import React, { useState, useCallback, useEffect } from "react";
import {
  Layout,
  Page,
  FooterHelp,
  Card,
  Link,
  Button,
  FormLayout,
  TextField,
  AccountConnection,
  ChoiceList,
  SettingToggle,
  LegacyCard,
  EmptyState,
} from "@shopify/polaris";
import * as Icons from "@shopify/polaris-icons";
import HeroImage from "./readable.png";
import { ReactTagManager } from "react-gtm-ts";

import Client, { shared, Oauth } from "@shoplinedev/appbridge";

const CLIENT_ID = "05f14a5b7a9214102a0706151ab0c09a8eb7e225";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [isUpgraded, setIsUpgraded] = React.useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    try {
      const app = Client.createApp({
        appKey: CLIENT_ID,
        host: shared.getHost(),
      });
      const urlQuery = new URLSearchParams(window.location.search);
      if (urlQuery.has("sign")) {
        Oauth.create(app).invoke({
          scope: "write_script_tags,read_script_tags,read_subscription_contracts,write_subscription_contracts",
          appKey: CLIENT_ID,
          redirectUri:
            "https://certifiedcode.wixsite.com/readable/_functions/shopline/auth",
        });
      }
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
    getTrial();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setSettings();
    } else {
      setIsLoading(false);
    }
  }, [isEnabled]);

  const BASE_URL = `https://certifiedcode.wixsite.com/readable/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          // window.Intercom("boot", {
          //   email: data.email,
          //   user_hash: data.hash,
          // });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
      })
      .catch(() => {});
  }

  function setSettings() {
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: isEnabled,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
      })
      .catch(() => {});
  }

  function getTrial() {
    fetch(`${BASE_URL}/premium`, {
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTrialRemainingDate(data?.trial);
      });
  }

  // if (token) {
  //   window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=865ea807-f2a8-4aa2-9df8-94aee1168023&redirectUrl=https://certifiedcode.wixsite.com/readable/_functions/@certifiedcode/base-backend/auth`;
  //   return (
  //     <WixDesignSystemProvider features={{ newColorsBranding: true }}>
  //       <Page height="100vh">
  //         <Page.Content>
  //           <Box height={"90vh"} direction="vertical" verticalAlign="middle">
  //             <Loader text="Loading" />
  //           </Box>
  //         </Page.Content>
  //       </Page>
  //     </WixDesignSystemProvider>
  //   );
  // }

  return (
    <Page>
        <LegacyCard sectioned>
          <EmptyState
            fullWidth
            heading="Readable: WCAG Accessibility"
            action={{ content: "Enable" }}
            secondaryAction={{
              content: "Upgrade to Enable Readable",
              // url: "https://help.shopify.com",
            }}
            image={"https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"}
          >
            <p>
              Readable is a WCAG accessibility tool that helps you make your
              website more accessible to people with disabilities.
            </p>
          </EmptyState>
        </LegacyCard>
    </Page>
  );

  // return (
  //   <WixDesignSystemProvider features={{ newColorsBranding: true }}>
  //     <Card>
  //       <MarketingPageLayout
  //         removeImageHorizontalPadding
  //         removeImageVerticalPadding
  //         content={
  //           <Box height="840px" verticalAlign="middle">
  //             <MarketingPageLayoutContent
  //               title="Readable: WCAG Accessibility"
  //               content="Readable is a WCAG accessibility tool that helps you make your website more accessible to people with disabilities."
  //               actions={
  //                 <Box direction="vertical" gap="SP1">
  //                   <FormField label="Enable Readable" labelPlacement="left">
  //                     <Switch
  //                       checked={isEnabled}
  //                       onChange={() => setIsEnabled(!isEnabled)}
  //                     />
  //                   </FormField>
  //                   <Card>
  //                     <Card.Content>
  //                       <CircularProgressBar
  //                         label={
  //                           isUpgraded
  //                             ? "You're on Premium"
  //                             : `You have ${trialRemainingDate} days left on your trial`
  //                         }
  //                         skin={isUpgraded ? "standard" : "premium"}
  //                         showProgressIndication
  //                         value={isUpgraded ? 100 : 1 - trialRemainingDate / 30}
  //                       />
  //                     </Card.Content>
  //                   </Card>
  //                   <Button
  //                     skin="premium"
  //                     as="a"
  //                     href={`https://www.wix.com/apps/upgrade/${"865ea807-f2a8-4aa2-9df8-94aee1168023"}?appInstanceId=${
  //                       (instanceData as any)?.instance?.instanceId
  //                     }`}
  //                     target="_blank"
  //                   >
  //                     Upgrade Now
  //                   </Button>
  //                   <Button
  //                     skin="inverted"
  //                     as="a"
  //                     href="https://www.wix.com/app-market/add-review/865ea807-f2a8-4aa2-9df8-94aee1168023"
  //                     target="_blank"
  //                   >
  //                     Rate Readable 5 stars
  //                   </Button>
  //                 </Box>
  //               }
  //             />
  //           </Box>
  //         }
  //         image={<Image transparent borderRadius={0} src={HeroImage} />}
  //       />
  //     </Card>
  //   </WixDesignSystemProvider>
  // );
}

export default App;